<template>
  <div class="other-content">
      <div class="other-content__list" v-if="status_list.length">
        <div class="other-content__item" v-for="(item, index) in status_list" :key="index">
          <div class="popup__label col-5">
            <b-form-group
                label="crm_status"
                label-for="input-horizontal"
                label-size="sm"
                label-cols="12"
                content-cols="12"
                class="mb-0"
                style="width: 100%;"
            >

              <v-select
                  id="crm_status"
                  v-model="item.crm_status"
                  class="select-input ml-0"
                  :options="orderStatusList.data"
                  :reduce="(item) => item.id"
                  label="title"
              />
            </b-form-group>
          </div>
          <div class="popup__label col-5">
            <b-form-group
                label-cols="12"
                label="order_status"
                label-for="input-horizontal"
                label-size="sm"
                content-cols-sm="12"
                class="mb-0"
                style="width: 100%;"
            >
              <b-form-input v-model="item.order_status">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="popup__label col-1 remove-status"
               v-if="status_list.length > 1"
            @click="removeStatusItem(item)"
          >
            <BasketIcon
                :fillColor="'#689CE7'"
            />
          </div>
        </div>
      </div>
      <b-button
          class="auth__btn btn-sm p-1 pl-4 pr-4 active"
          variant="primary"
          @click="addNewStatus"
      >Додати статус
      </b-button>
  </div>
</template>
<script>
import icons from "../../../../mixins/icons/index.js";
import {mapGetters} from "vuex";
export default {
  props:['status_list'],
  mixins: [icons],
  computed:{
    ...mapGetters({
      orderStatusList: 'orders/orderStatusList',
    })
  },
  methods:{
    addNewStatus(){
      this.$emit('addToStatusList')
      this.$forceUpdate();
    },
    removeStatusItem(item){
      this.$emit('removeStatusItem', item)
      this.$forceUpdate();
    }
  }
}
</script>
<style lang="scss" scoped>
.other-content{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px 5px;
  &__list{
    background-color: #f3f3f3;
    padding: 15px;
  }
  &__item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    background-color: #dadada;
    input, .v-select{
      background-color: white;
    }
  }
  .remove-status{
    padding: 20px 20px 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      opacity: 0.7;
    }
  }
}
</style>
